import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { DavAtomsAngularModule } from "@npm-davi/davi-coe-atoms-angular-lib";
import { CoreModule } from "../core/core.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

declare const window: Window &
  typeof globalThis & {
    dtrum: any;
  };
  
export class CustomErrorHandler implements ErrorHandler {
  handleError(error: any): void {
     // Report the error
     window.dtrum?.reportError(error);
     // custom error handling
  }
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DavAtomsAngularModule,
    RouterModule,
    HttpClientModule,
  ],
  exports: [DavAtomsAngularModule],
  providers: [...CoreModule.providers()],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
