import { EventEmitter, Injectable } from "@angular/core";
import { cloneJSON } from "../../common";
import { PAGE } from "../../constants/pages";
import { ModalState, PageState, State } from "../../model/state.model";
import { StateRepository } from "../../repository/state.repository";

@Injectable({
  providedIn: "root",
})
export class StateService implements StateRepository {
  private readonly state: State;
  public $dataModal: EventEmitter<ModalState>;
  public $showLoader: EventEmitter<boolean>;

  constructor() {
    this.state = {
      channel: 37,
      sessionID: "",
      title: "title dummy",
      url: "url dummy",
      zone: "Privada",
      userId: "",
      entryPoint: "home",
      currentPage: PAGE.ONBOARDING,
      omitLoadParams: false,
      pagesLoaded: {},
      token: "",
      isBreB: false,
      modals: {
        TAGGING: {
          pageview: {
            eventCategory: "{{channel}} - Visual Transferencias",
            eventAction: "Modal - Pageview - {{title}}",
            eventLabel:
              "Modal - {{title}} - {{description}} - Origen Modal: {{originTitle}} ",
            eventvalue: "",
            event: "pageview",
          },
          click: {
            eventCategory: "{{channel}} - Visual Transferencias",
            eventAction: "Modal - Click - {{buttonName}}",
            eventLabel:
              "Modal - {{title}} - {{description}} - Origen Modal: {{originTitle}} - {{buttonName}}",
            eventvalue: "",
            event: "eventClick",
          },
        },
        ERROR: {
          type: "error",
          title: "Intente más tarde",
          description:
            "Por el momento, no podemos atender su solicitud, ¡discúlpenos!",
          principalButton: "Aceptar",
          principalPostmessage: {
            fn: "appFinish",
            target: "WEB",
            message: {
              status: "fail",
              statusCode: 500,
              statusMessage: "op_{{entryPoint}}",
              token: "{{userId}}",
            },
          },
        },
      },
    };
    this.$dataModal = new EventEmitter();
    this.$showLoader = new EventEmitter();
  }

  public getState(): State {
    return cloneJSON(this.state);
  }

  public getChannel(): number {
    return this.getState().channel;
  }

  public getChannelName(): string {
    switch (this.getState().channel) {
      case 37:
        return "SAPP";
      case 16:
        return "DAVICOM";
      default:
        return "SAPP";
    }
  }

  public setChannel(channel: number): void {
    this.state.channel = channel;
  }

  public getClientId(): string {
    return this.getState().sessionID;
  }

  public setClientId(clientId: string): void {
    this.state.sessionID = clientId;
  }

  public getTitle(): string {
    return this.getState().title;
  }

  public setTitle(title: string): void {
    this.state.title = title;
  }

  public getUrl(): string {
    return this.getState().url;
  }

  public setUrl(url: string): void {
    this.state.url = url;
  }

  public getZone(): string {
    return this.getState().zone;
  }

  public setZone(zone: string): void {
    this.state.zone = zone;
  }

  public getUserId(): string {
    return this.getState().userId;
  }

  public setUserId(userId: string): void {
    this.state.userId = userId;
  }

  public getEntryPoint(): string {
    return this.getState().entryPoint;
  }

  public setEntryPoint(entryPoint: string): void {
    this.state.entryPoint = entryPoint;
  }

  public getBearerToken(): string {
    return this.getState().token;
  }

  public setBearerToken(token: string): void {
    this.state.token = token;
  }

  public getCurrentPage(): PAGE {
    return this.getState().currentPage;
  }

  public setCurrentPage(pageId: PAGE): void {
    this.state.currentPage = pageId;
  }

  public getPagesLoaded(): { [pageId in PAGE]?: PageState } {
    return this.getState().pagesLoaded;
  }

  public setPagesLoaded(steps: { [pageId in PAGE]?: PageState }): void {
    this.state.pagesLoaded = steps;
  }

  public setIsBreB( isBreB: boolean): void {
    this.state.isBreB = isBreB;
  }

  public getIsBreB(): boolean {
    return this.getState().isBreB;
  }

  public getModals(): {
    TAGGING: Record<string, any>;
    [key: string]: ModalState | Record<string, any>;
  } {
    return this.getState().modals;
  }

  public setModals(modals: {
    TAGGING: Record<string, any>;
    [key: string]: ModalState | Record<string, any>;
  }): void {
    this.state.modals = modals;
  }

  public getOmitLoadParams(): boolean {
    return this.getState().omitLoadParams;
  }
  
  public setOmitLoadParams(omitLoadParams: boolean): void {
    this.state.omitLoadParams = omitLoadParams;
  }
}
