import { Provider } from "@angular/core";
import { PageComponent } from "./page.component";
import { ApiRepository } from "./repository/api.repository";
import { CryptoRepository } from "./repository/crypto.repository";
import { LoggerRepository } from "./repository/logger.repository";
import { ParamsRepository } from "./repository/params.repository";
import { PostmessageRepository } from "./repository/postmessage.repository";
import { StateRepository } from "./repository/state.repository";
import { TaggingRepository } from "./repository/tagging.repository";
import { ApiService } from "./service/api/api.service";
import { CryptoService } from "./service/crypto/crypto.service";
import { LoggerService } from "./service/logger/logger.service";
import { ParamsService } from "./service/params/params.service";
import { PostmessageService } from "./service/postmessage/postmessage.service";
import { StateService } from "./service/state/state.service";
import { TaggingService } from "./service/tagging/tagging.service";
import { ApiUsecase } from "./usecase/api.usecase";
import { ModalUsecase } from "./usecase/modal.usecase";
import { ParamsUsecase } from "./usecase/params.usecase";

export class CoreModule {
  static export(): any[] {
    return [PageComponent];
  }

  static providers(): Provider[] {
    return [
      {
        provide: TaggingRepository,
        useClass: TaggingService,
      },
      {
        provide: StateRepository,
        useClass: StateService,
      },
      {
        provide: LoggerRepository,
        useClass: LoggerService,
      },
      {
        provide: CryptoRepository,
        useClass: CryptoService,
      },
      {
        provide: ParamsRepository,
        useClass: ParamsService,
      },
      {
        provide: PostmessageRepository,
        useClass: PostmessageService,
      },
      {
        provide: TaggingRepository,
        useClass: TaggingService,
      },
      {
        provide: ApiRepository,
        useClass: ApiService,
      },
      {
        provide: ModalUsecase,
        useClass: ModalUsecase,
      },
      {
        provide: ParamsUsecase,
        useClass: ParamsUsecase,
      },
      {
        provide: ApiUsecase,
        useClass: ApiUsecase,
      },
    ];
  }
}
