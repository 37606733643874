import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";

declare const window: Window &
  typeof globalThis & {
    dtrum: any;
  };

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() {
    window.addEventListener("unhandledrejection", (event) => {
      this.handleError(event.reason);
    });

    window.onerror = (message, source, lineno, colno, error) => {
      this.handleError(error);
    };
  }

  handleError(error: any): void {
    window.dtrum?.reportError(error);
    if (error instanceof HttpErrorResponse) {
      console.debug("Server error:", error);
    } else {
      console.debug("Client error:", error);
    }
  }
}
