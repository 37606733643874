import { Location } from "@angular/common";
import { inject, Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
import { renderJson } from "../../common";
import { IDENTIFIERS_TAGGING } from "../../constants/tagging";
import { Tagging } from "../../model/tagging.model";
import { LoggerRepository } from "../../repository/logger.repository";
import { StateRepository } from "../../repository/state.repository";
import { TaggingRepository } from "../../repository/tagging.repository";

declare const window: Window &
  typeof globalThis & {
    dataLayer: any;
  };

@Injectable({
  providedIn: "root",
})
export class TaggingService implements TaggingRepository {
  private readonly titleBrowser = inject(Title);
  private readonly location = inject(Location);
  private readonly loggerRepo = inject(LoggerRepository);
  private readonly stateRepo = inject(StateRepository);

  constructor() {
    this.initDataLayer();
  }

  sendTag(tag: Tagging) {
    const channel = this.stateRepo.getChannelName();
    const title = this.stateRepo.getTitle();
    const url = this.stateRepo.getUrl();
    const zone = this.stateRepo.getZone();

    // Complete data
    tag.data = {
      channel,
      title,
      url,
      zone,
      ...tag.data,
    };

    tag.structure = renderJson(tag.structure, tag.data);

    if (tag.key.includes(IDENTIFIERS_TAGGING.VIRTUALPAGEVIEW)) {
 
      // Cambiar titulo
      this.titleBrowser.setTitle(tag?.structure?.pageTitle);
      this.location.replaceState(tag?.structure?.pageUrl);

      this.stateRepo.setTitle(tag?.structure?.pageTitle);
      this.stateRepo.setUrl(tag?.structure?.pageUrl);
    }

    this.loggerRepo.debug("Enviando tagging", tag.structure);
    this.pushTag(tag.structure);
  }

  private initDataLayer() {
    //Creando NoScript
    this.loggerRepo.debug("Creando no script GTM");
    // Creación del NoScript
    if (typeof document !== "undefined") {
      const noscript = document.createElement("noscript");

      // Creación y configuración del iframe
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.style.visibility = "hidden";
      iframe.height = "0";
      iframe.width = "0";
      iframe.src =
        "https://www.googletagmanager.com/ns.html?id=" + environment.gtm_token;

      //Ponemos el iframe dentro del noscript
      noscript.appendChild(iframe);

      //Añadimos el tag noscript al body
      document.body.appendChild(noscript);

      //Creando ScriptGTM
      this.loggerRepo.debug("Creando script GTM");
      const script = document.createElement("script");
      script.type = "text/javascript";

      script.appendChild(
        document.createTextNode(
          `(function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${environment.gtm_token}');`
        )
      );
      document.head.appendChild(script);
    }
  }

  private pushTag(tag: any) {
    if (typeof document !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(tag);
    }
  }
}
