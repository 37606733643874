//
// Copyright (C) 2019 - Banco Davivienda S.A. y sus filiales.
//

export const environment = {
  env: 'local',
  production: false,
  apiUrl: "https://spi-api-integration.dvvapps.io",
  gtm_token: 'GTM-5CDM84W',
  publicKeyBackFront:"NDMsMTcxLDg4LDEzNSwyNTQsMTc5LDExMSwyMzAsMTA2LDQ2LDMyLDEyOCwxMDMsNjcsMTUyLDk5LDE0MywyNDQsOTMsODcsMzgsMTQsNzksMTQzLDUwLDYyLDYxLDE1NCwyMDIsMTIyLDE1NSwyNDk=",
  publicKeyFrontBack:"MjAyLDExMSwzMywxOTksMjE4LDI3LDE2MCwyMjIsMTU5LDExMSwxMzUsNDksNTQsNTcsMjE4LDE4MCwxNTIsMTAzLDExOSw0MywxNjgsNjQsMTYxLDk4LDksMTU2LDE4MCwxMzIsMjIwLDEyNSwxNywxMTE=",
  dynatrace: "https://js-cdn.dynatrace.com/jstag/1700f2c78c0/bf30686gkn/ac13b1966ae75b2c_complete.js"
};
