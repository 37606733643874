import { Injectable } from "@angular/core";
import { LoggerRepository } from "../../repository/logger.repository";

@Injectable({
  providedIn: "root",
})
export class LoggerService implements LoggerRepository {
  debug(message: string, data?: any): void {
    if (data) {
      console.debug(message, data);
    } else {
      console.debug(message);
    }
  }

  error(message: string, data?: any): void {
    if (data) {
      console.log(message, data);
    } else {
      console.log(message);
    }
  }
}
