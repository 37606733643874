import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "../../../environments/environment";
import { PAGE } from "../../constants/pages";
import { ParamsRepository } from "../../repository/params.repository";

@Injectable({
  providedIn: "root",
})
export class ParamsService implements ParamsRepository {
  private readonly url: string;
  private readonly http = inject(HttpClient);

  constructor() {
    this.url = environment.apiUrl + "/v1/params";
  }

  public getParam(paramId: string, token: string, pageId?: PAGE): Promise<any> {
    let urlParam = this.url + `/${paramId}`;
    if (pageId) {
      urlParam += `/${pageId}`;
    }

    const httpOptionsEmpty = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + token,
      }),
    };

    return lastValueFrom(this.http.get<any>(urlParam, httpOptionsEmpty));
  }
}
