import { inject, Injectable } from "@angular/core";
import { renderJson } from "../../common";
import { LoggerRepository } from "../../repository/logger.repository";
import { StateRepository } from "../../repository/state.repository";

@Injectable({
  providedIn: "root",
})
export class PostmessageService {
  private readonly stateRepo = inject(StateRepository);
  private readonly loggerRepo = inject(LoggerRepository);

  sendPostmessage(postmessage: any, data: any = {}) {
    if (postmessage) {
      postmessage = renderJson(postmessage, data);

      let origin;
      try {
        origin = window.parent.origin;
      } catch {
        origin = "*";
      }

      this.loggerRepo.debug("Enviando postmessage", postmessage);

      if (typeof document !== "undefined") {
        if (this.stateRepo.getChannel() === 37) {
          window.parent.postMessage(postmessage, origin);
        } else {
          window.top!.postMessage(postmessage, origin);
        }
      }
    }
  }
}
