//
// Copyright (C) 2019 - Banco Davivienda S.A. y sus filiales.
//

export const environment = {
  env: 'local',
  production: false,
  apiUrl: "https://h5e9qx4yj6.execute-api.us-east-1.amazonaws.com/stage",
  gtm_token: 'GTM-5CDM84W',
  publicKeyBackFront:"NDMsMTcxLDg4LDEzNSwyNTQsMTc5LDExMSwyMzAsMTA2LDQ2LDMyLDEyOCwxMDMsNjcsMTUyLDk5LDE0MywyNDQsOTMsODcsMzgsMTQsNzksMTQzLDUwLDYyLDYxLDE1NCwyMDIsMTIyLDE1NSwyNDk=",
  publicKeyFrontBack:"MjAyLDExMSwzMywxOTksMjE4LDI3LDE2MCwyMjIsMTU5LDExMSwxMzUsNDksNTQsNTcsMjE4LDE4MCwxNTIsMTAzLDExOSw0MywxNjgsNjQsMTYxLDk4LDksMTU2LDE4MCwxMzIsMjIwLDEyNSwxNywxMTE=",

  transferApiUrl: "http://localhost:3000",
};
