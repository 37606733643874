import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { DavAtomsAngularModule } from "@npm-davi/davi-coe-atoms-angular-lib";
import { CoreModule } from "../core/core.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LocalComponentsModule } from "./shared/local-components/local-components.module";
import { GlobalErrorHandler } from "./shared/handler/global-error.handler";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DavAtomsAngularModule,
    RouterModule,
    HttpClientModule,
    LocalComponentsModule,
  ],
  exports: [DavAtomsAngularModule],
  providers: [...CoreModule.providers(), { provide: ErrorHandler, useClass: GlobalErrorHandler }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
