import {
  Component,
  inject,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from "@angular/core";
import { IModal } from "@npm-davi/davi-coe-atoms-lib/types";
import { Subscription } from "rxjs";
import { ModalState } from "../core/model/state.model";
import { StateRepository } from "../core/repository/state.repository";
import { environment } from "../environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnDestroy, OnInit {
  modalData: WritableSignal<IModal>;
  subs: Subscription[];
  showLoader: boolean;

  private readonly stateRepo = inject(StateRepository);

  constructor() {
    this.modalData = signal({
      modalTitle: "Intente más tarde",
      type: "error",
      content: "Por el momento, no podemos atender esta solicitud.",
      primaryButton: "Aceptar",
      secondaryButton: "Cerrar",
    });

    this.subs = [];
    this.showLoader = false;

    this.subs.push(
      this.stateRepo.$dataModal.subscribe((data: ModalState) => {
        this.modalData.set({
          modalTitle: data.title,
          type: data.type,
          content: data.description,
          primaryButton: data.principalButton,
          secondaryButton: data.secondaryButton,
        });
      })
    );

    this.subs.push(
      this.stateRepo.$showLoader.subscribe((show: boolean) => {
        this.showLoader = show;
      })
    );
  }

  ngOnInit(): void {
    if (typeof document !== "undefined") {
      const script = document.createElement('script');
      script.src = environment.dynatrace;
      script.async = true;
      document.head.appendChild(script);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
