import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "../../../environments/environment";
import { ENDPOINT } from "../../constants/pages";
import { Request } from "../../model/request.model";
import { Response } from "../../model/response.model";
import { ApiRepository } from "../../repository/api.repository";

@Injectable({
  providedIn: "root",
})
export class ApiService implements ApiRepository {
  private readonly url: string;
  private readonly http = inject(HttpClient);

  constructor() {
    this.url = environment.apiUrl;
  }

  sendRequest(
    endpoint: ENDPOINT,
    request: Request,
    token: string
  ): Promise<Response> {
    const httpOptionsEmpty = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    return lastValueFrom(
      this.http.post<Response>(this.url + endpoint, request, httpOptionsEmpty)
    );
  }
}
