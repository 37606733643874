import * as sbxUtilities from "@npm-davi/davi-coe-shield-cipher-utils-lib";
import { environment } from "../../../environments/environment";
import { CryptoRepository } from "../../repository/crypto.repository";

export class CryptoService implements CryptoRepository {
  private readonly properties: sbxUtilities.IPropertiesEncrypt = {
    Parameters: {
      Time: 3,
      Body: 0,
      Iv: 9,
      Pk1: 2,
      Pk2: 6,
      Total: 10,
    },
    Expiration: {
      Time: 3600,
      Unit: "seconds",
    },
    Seed: {
      nonce: sbxUtilities.NaclSbxUtil.generateCustomNonce("White-Token"),
    },
  };

  async encrypt(data: any): Promise<string> {
    const properties = this.properties;
    try {
      const encryptBody = sbxUtilities.NaclSbxUtil.generateJWTPublicKey(
        data,
        environment.publicKeyFrontBack,
        properties
      );
      if (typeof encryptBody === "object" && "Status" in encryptBody) {
        throw encryptBody;
      }
      return encryptBody;
    } catch (error: unknown) {
      const errorMsg = error as sbxUtilities.MsgRsErrorDef;
      throw new Error(
        `[CryptoService] - Error in data encryption  - Details: ${JSON.stringify(
          errorMsg
        )}`
      );
    }
  }

  async decrypt(data: string): Promise<any> {
    const properties = this.properties;
    try {
      const decryptBody = sbxUtilities.NaclSbxUtil.validateJWT(
        data,
        environment.publicKeyBackFront,
        properties as sbxUtilities.IPropertiesDecrypt
      );
      if (typeof decryptBody === "object" && "Status" in decryptBody) {
        throw decryptBody;
      }
      return decryptBody;
    } catch (error: unknown) {
      const errorMsg = error as sbxUtilities.MsgRsErrorDef;
      throw new Error(
        `[CryptoService] - Error in data decryption  - Details: ${JSON.stringify(
          errorMsg
        )}`
      );
    }
  }
}
